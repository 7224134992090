import React from "react";
import styles from "./StopRecordingButton.module.scss";

interface Props {
  className?: string;
  onClick?: () => void;
}

export const StopRecordingButton: React.FC<Props> = (props) => {
  return (
    <div className={styles.btn}>
      <div className={styles.btn__bg1}></div>
      <div className={styles.btn__bg2}></div>
      <div className={styles.btn__bg3}></div>
      <button onClick={props.onClick}>
        <div className={styles.btn__box}></div>
      </button>
    </div>
  );
};
