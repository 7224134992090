import React from "react";
import styles from "./RecordButton.module.scss";
import { cl } from "../../utils";

interface Props {
  className?: string;
  onClick?: () => void;
}

export const RecordButton: React.FC<Props> = (props) => {
  const className = cl(
    {
      [styles.btn]: true,
    },
    props.className
  );
  return (
    <button className={className} onClick={props.onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470 470">
        <path d="M432.5 12.5h-395C16.822 12.5 0 29.323 0 50v300c0 20.677 16.822 37.5 37.5 37.5h145v55H130a7.5 7.5 0 000 15h210a7.5 7.5 0 000-15h-52.5v-55h145c20.678 0 37.5-16.823 37.5-37.5V50c0-20.677-16.822-37.5-37.5-37.5zm-160 430h-75v-55h75v55zM455 350c0 12.406-10.093 22.5-22.5 22.5h-395C25.093 372.5 15 362.406 15 350v-22.5h440V350zm0-37.5H15V50c0-12.406 10.093-22.5 22.5-22.5h395c12.407 0 22.5 10.094 22.5 22.5v262.5z" />
        <path d="M432.5 42.5h-395A7.5 7.5 0 0030 50v240a7.5 7.5 0 007.5 7.5h325a7.5 7.5 0 000-15H45v-225h380v225h-32.5a7.5 7.5 0 000 15h40a7.5 7.5 0 007.5-7.5V50a7.5 7.5 0 00-7.5-7.5z" />
      </svg>
      <div className={styles.btn__container}>
        <div className={styles.btn__circle}></div>
        <div className={styles.btn__text}>REC</div>
      </div>
    </button>
  );
};
