import React, { useState } from "react";
import styles from "./App.module.scss";
import { Player } from "../Player/Player";
import { cl, downloadFile } from "../../utils";
import { CircleButton } from "../CircleButton/CircleButton";
import { Modal } from "../Modal/Modal";

function App() {
  const [showSettings, setShowSettings] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordResult, setRecordResult] = useState("");

  const className = cl({
    [styles.App]: true,
    [styles["App--recording"]]: isRecording,
  });

  const onStartRecording = () => {
    setIsRecording(true);
  };

  const onStopRecording = (url: string) => {
    setRecordResult(url);
    setIsRecording(false);
  };

  const downloadRecording = () => {
    downloadFile(recordResult);
  };

  const discardRecording = () => {
    setRecordResult("");
  };

  const openSettings = () => {
    setShowSettings(true);
  };

  const closeSettings = () => {
    setShowSettings(false);
  };

  return (
    <div className={className}>
      <h1 className={styles.App__title}>
        {isRecording ? "Recording" : "screenc.app"}
      </h1>
      <h2 className={styles.App__subtitle}>
        {!recordResult &&
          (isRecording
            ? "Click stop to preview your recording"
            : "Click to start recording your screen")}
        {recordResult &&
          "Save your recording or discard it to create a new one"}
      </h2>
      <Player
        onStartRecording={onStartRecording}
        onStopRecording={onStopRecording}
        src={recordResult}
      />
      {!isRecording && !recordResult && (
        <div className={styles.App__actions}>
          <CircleButton color="blue" type="settings" onClick={openSettings} />
          <Modal show={showSettings} onClose={closeSettings} />
        </div>
      )}
      {!isRecording && recordResult && (
        <div className={styles.App__actions}>
          <CircleButton
            color="blue"
            type="download"
            onClick={downloadRecording}
          />
          <CircleButton type="discard" color="red" onClick={discardRecording} />
        </div>
      )}
    </div>
  );
}

export default App;
