import React from "react";
import styles from "./Modal.module.scss";
import { cl } from "../../utils";

interface Props {
  className?: string;
  show?: boolean;
  onClose?: () => void;
}

export const Modal: React.FC<Props> = (props) => {
  const className = cl(
    {
      [styles.Modal]: true,
      //   [styles["CircleButton--blue"]]: props.color === "blue",
    },
    props.className
  );

  const closeModal = () => {
    props.onClose && props.onClose();
  };

  return props.show ? (
    <div className={className}>
      <div className={styles.Modal__content}>
        <h1>Hello</h1>
        <p>This is work in progress</p>
        <button onClick={closeModal}>OK</button>
      </div>
    </div>
  ) : (
    <></>
  );
};
