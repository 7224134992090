import React from "react";
import styles from "./CircleButton.module.scss";
import { cl } from "../../utils";

interface Props {
  className?: string;
  type: "discard" | "download" | "settings";
  onClick?: () => void;
  color?: "blue" | "red";
}

export const CircleButton: React.FC<Props> = (props) => {
  const className = cl(
    {
      [styles.CircleButton]: true,
      [styles["CircleButton--blue"]]: props.color === "blue",
      [styles["CircleButton--red"]]: props.color === "red",
    },
    props.className
  );
  return (
    <button className={className} onClick={props.onClick}>
      {(() => {
        switch (props.type) {
          case "discard":
            return (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 365.696 365.696"
                style={{ width: 18 }}
              >
                <defs />
                <path d="M243.188 182.86L356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0" />
              </svg>
            );
          case "download":
            return (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 492 492"
                style={{ width: 22 }}
              >
                <path d="M442.668 268.536l-16.116-16.12c-5.06-5.068-11.824-7.872-19.024-7.872-7.208 0-14.584 2.804-19.644 7.872L283.688 355.992V26.924C283.688 12.084 272.856 0 258.02 0h-22.804c-14.832 0-28.404 12.084-28.404 26.924v330.24L102.824 252.416c-5.068-5.068-11.444-7.872-18.652-7.872-7.2 0-13.776 2.804-18.84 7.872l-16.028 16.12c-10.488 10.492-10.444 27.56.044 38.052l177.576 177.556c5.056 5.056 11.84 7.856 19.1 7.856h.076c7.204 0 13.972-2.8 19.028-7.856l177.54-177.552c10.496-10.488 10.496-27.564 0-38.056z"></path>
              </svg>
            );
          case "settings":
            return (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style={{ width: 22 }}
              >
                <defs />
                <path d="M464.866 192.8h-10.87a206.755 206.755 0 00-13.323-32.097l7.698-7.698c18.665-18.645 18.165-48.525.006-66.665l-22.711-22.71c-18.129-18.153-48.015-18.679-66.665-.007l-7.704 7.704A206.893 206.893 0 00319.2 58.004V47.133C319.2 21.144 298.056 0 272.066 0h-32.133C213.944 0 192.8 21.144 192.8 47.133v10.871a206.698 206.698 0 00-32.097 13.323l-7.697-7.697c-18.613-18.635-48.498-18.198-66.664-.006L63.629 86.335c-18.152 18.132-18.677 48.014-.006 66.665l7.704 7.704a206.714 206.714 0 00-13.323 32.097h-10.87C21.145 192.8 0 213.944 0 239.933v32.134C0 298.056 21.145 319.2 47.134 319.2h10.87a206.755 206.755 0 0013.323 32.097l-7.698 7.698c-18.665 18.645-18.165 48.525-.006 66.665l22.711 22.71c18.129 18.153 48.015 18.679 66.665.007l7.704-7.704a206.893 206.893 0 0032.097 13.323v10.871c0 25.989 21.145 47.133 47.134 47.133h32.133c25.989 0 47.134-21.144 47.134-47.133v-10.871a206.698 206.698 0 0032.097-13.323l7.697 7.697c18.613 18.635 48.497 18.198 66.664.006l22.713-22.712c18.152-18.132 18.677-48.014.006-66.665l-7.704-7.704a206.714 206.714 0 0013.323-32.097h10.87c25.989 0 47.134-21.144 47.134-47.133v-32.134C512 213.944 490.855 192.8 464.866 192.8zM256 367.4c-61.427 0-111.4-49.974-111.4-111.4S194.573 144.6 256 144.6 367.4 194.574 367.4 256 317.427 367.4 256 367.4z" />
              </svg>
            );
        }
      })()}
    </button>
  );
};
