import { saveAs } from "file-saver";

export function cl(
  classObj: { [key: string]: boolean },
  extraClasses?: string
): string {
  return Object.entries(classObj)
    .filter((item) => {
      if (item[0] === "undefined") {
        // eslint-disable-next-line no-console
        console.warn("Unknown class name in", classObj);
        return false;
      }
      return true;
    })
    .filter((item) => item[1])
    .map((item) => item[0])
    .concat(extraClasses?.split(" ") || [])
    .join(" ");
}

export function downloadFile(contentUrl: string) {
  const d = new Date();
  let t = d.toISOString().replace(/:/g, "-");
  t = t.substring(0, t.lastIndexOf("."));
  saveAs(contentUrl, `Recording_${t}`);
}
