import React, { useState, useRef } from "react";
import { CSSTransition } from "react-transition-group";

import styles from "./Player.module.scss";
import { RecordButton } from "../RecordButton/RecordButton";
import { StopRecordingButton } from "../StopRecordingButton/StopRecordingButton";

interface Props {
  onStartRecording?: () => void;
  onStopRecording?: (blobUrl: string) => void;
  src?: string;
}

let stream: MediaStream;
let recorder: MediaRecorder;

export const Player: React.FC<Props> = (props) => {
  const recordingPlayer = useRef<HTMLVideoElement>(null);
  const resultPlayer = useRef<HTMLVideoElement>(null);

  const [isRecording, setIsRecording] = useState(false);

  const startRecording = async () => {
    stream = await (navigator.mediaDevices as any).getDisplayMedia({
      video: { mediaSource: "screen", frameRate: 60 },
      audio: true,
    } as MediaStreamConstraints);
    recorder = new MediaRecorder(stream, {
      videoBitsPerSecond: 6500000,
      //mimeType: "video/webm;codecs=vp9.0",
      //   mimeType: "video/x-matroska;codecs=avc1",
      //   videoBitsPerSecond: 300000000,
    });
    props.onStartRecording && props.onStartRecording();
    setIsRecording(true);
    if (recordingPlayer.current) {
      recordingPlayer.current.srcObject = stream;
      recordingPlayer.current.play();
    }

    const chunks: Array<Blob> = [];
    recorder.ondataavailable = (e) => chunks.push(e.data);
    recorder.onstop = () => {
      stream.getTracks().map((s) => s.stop());
      const completeBlob = new Blob(chunks, { type: chunks[0].type });
      if (recordingPlayer.current) {
        recordingPlayer.current.pause();
      }
      props.onStopRecording &&
        props.onStopRecording(URL.createObjectURL(completeBlob));
      setIsRecording(false);
    };
    recorder.start();
  };

  const stopRecording = () => {
    recorder.stop();
    stream.getVideoTracks()[0].stop();
  };

  return (
    <div className={styles.Player}>
      <CSSTransition
        in={isRecording}
        timeout={{
          exit: 400,
        }}
        classNames={{
          exit: styles["Player__video--animation-exit"],
        }}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <video
          className={styles.Player__video}
          ref={recordingPlayer}
          muted
        ></video>
      </CSSTransition>
      <CSSTransition
        in={props.src ? true : false}
        timeout={{
          enter: 0,
        }}
        classNames={{
          enter: styles["Player__video--animation-enter"],
        }}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <video
          className={styles.Player__video}
          ref={resultPlayer}
          controls
          autoPlay
          src={props.src}
        ></video>
      </CSSTransition>
      {!props.src && !isRecording && <RecordButton onClick={startRecording} />}
      {!props.src && isRecording && (
        <StopRecordingButton
          onClick={stopRecording}
          className={styles["Player__btn"]}
        />
      )}
    </div>
  );
};
